// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ionic-hhb-mobile-primary: mat.define-palette(mat.$indigo-palette);
$ionic-hhb-mobile-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$ionic-hhb-mobile-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ionic-hhb-mobile-theme: mat.define-light-theme(
  (
    color: (
      primary: $ionic-hhb-mobile-primary,
      accent: $ionic-hhb-mobile-accent,
      warn: $ionic-hhb-mobile-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ionic-hhb-mobile-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #241e20;
  --ion-color-primary-rgb: 36, 30, 32;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #201a1c;
  --ion-color-primary-tint: #3a3536;

  --ion-color-secondary: #ffc107;
  --ion-color-secondary-rgb: 255, 193, 7;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0aa06;
  --ion-color-secondary-tint: #ffc720;

  --ion-color-tertiary: #388697;
  --ion-color-tertiary-rgb: 56, 134, 151;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #317685;
  --ion-color-tertiary-tint: #4c92a1;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #ab041a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #3a3b3b;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --background: rgb(233, 233, 233);

  --ion-color-cardtitle: #3a3a3a;
  --ion-color-cardtitle-rgb: 58, 58, 58;
  --ion-color-cardtitle-contrast: #ffffff;
  --ion-color-cardtitle-contrast-rgb: 255, 255, 255;
  --ion-color-cardtitle-shade: #333333;
  --ion-color-cardtitle-tint: #4e4e4e;

  --ion-color-lable: black;
  --ion-color-lable-rgb: 30, 74, 141;
  --ion-color-lable-contrast: #ffffff;
  --ion-color-lable-contrast-rgb: 255, 255, 255;
  --ion-color-lable-shade: #1a417c;
  --ion-color-lable-tint: #355c98;

  --ion-color-icon: black;
  --ion-color-icon-rgb: 30, 74, 141;
  --ion-color-icon-contrast: #ffffff;
  --ion-color-icon-contrast-rgb: 255, 255, 255;
  --ion-color-icon-shade: #1a417c;
  --ion-color-icon-tint: #355c98;

  --highlight-background: #ab041a;

  --toggle-checked-background: #ffc107; /* Replace with the color you want when the toggle is checked */
  --toggle-unchecked-background: #ccc; /* Replace with the color you want when the toggle is unchecked */
}

.ion-color-lable {
  --ion-color-base: var(--ion-color-lable);
  --ion-color-base-rgb: var(--ion-color-lable-rgb);
  --ion-color-contrast: var(--ion-color-lable-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lable-contrast-rgb);
  --ion-color-shade: var(--ion-color-lable-shade);
  --ion-color-tint: var(--ion-color-lable-tint);
}

.ion-color-icon {
  --ion-color-base: var(--ion-color-icon);
  --ion-color-base-rgb: var(--ion-color-icon-rgb);
  --ion-color-contrast: var(--ion-color-icon-contrast);
  --ion-color-contrast-rgb: var(--ion-color-icon-contrast-rgb);
  --ion-color-shade: var(--ion-color-icon-shade);
  --ion-color-tint: var(--ion-color-icon-tint);
}

.ion-color-cardtitle {
  --ion-color-base: var(--ion-color-cardtitle);
  --ion-color-base-rgb: var(--ion-color-cardtitle-rgb);
  --ion-color-contrast: var(--ion-color-cardtitle-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cardtitle-contrast-rgb);
  --ion-color-shade: var(--ion-color-cardtitle-shade);
  --ion-color-tint: var(--ion-color-cardtitle-tint);
}

/*
 * Dark Colors
 * ------------------
 */

body.dark {
  --ion-color-primary: #585858;
  --ion-color-primary-rgb: 88, 88, 88;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #4d4d4d;
  --ion-color-primary-tint: #696969;

  --ion-color-secondary: #585858;
  --ion-color-secondary-rgb: 88, 88, 88;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4d4d4d;
  --ion-color-secondary-tint: #696969;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * -------------------
 */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #1c1c1c;
  --ion-item-background-activated: #313131;
}

/*
 * Material Design Dark Theme
 * ------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1a1b1e;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
