/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Swiper bundle */
@import 'swiper/css/bundle';

/* Animation */
@import '~animate.css/animate.min.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

:host {
  ion-content {
    --background: rgb(226, 226, 226) !important;
  }
}

.paymentPopover {
  color: black;
  --width: 83%;
  --height: auto !important;
  --box-shadow: none;
  --border-radius: 10px !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.6);
}

ion-popover {
  .popover-wrapper {
    .popover-content {
      width: 80vw;
      max-width: 500px;
    }
  }
}

$popover-md-width: 320px;
$popover-ios-width: 320px;
$popover-wp-width: 320px;

.paymentPopover .popover-content {
  width: 320px;
  left: 7% !important;
}

.logo {
  width: 260px;
  border-radius: 5px;
}

.input-height {
  padding: 0px 0px !important;
  height: 54px !important;
}

ion-input {
  --placeholder-height: 50px;
}

::-webkit-input-placeholder {
  font-size: medium !important;
  --placeholder-height: 100px !important;
  height: 100px !important;
}

.start-icon {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 8px !important;
  padding-bottom: 13px !important;
  height: 32px;
  width: 32px;
}

.eye-icon {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 8px !important;
  height: 32px;
  width: 32px;
}

ion-app {
  margin-top: env(safe-area-inset-top);
}

.input-clear-icon.sc-ion-input-md {
  width: 48px !important;
  height: 48px !important;
}

.input-clear-icon.sc-ion-input-ios {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'><path%20fill='rgb%2870,70,70%29'%20d='M403.1,108.9c-81.2-81.2-212.9-81.2-294.2,0s-81.2,212.9,0,294.2c81.2,81.2,212.9,81.2,294.2,0S484.3,190.1,403.1,108.9z%20M352,340.2L340.2,352l-84.4-84.2l-84,83.8L160,339.8l84-83.8l-84-83.8l11.8-11.8l84,83.8l84.4-84.2l11.8,11.8L267.6,256L352,340.2z'/></svg>") !important;
}

.input-clear-icon.sc-ion-input-md {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'><polygon%20fill='rgb%2870,70,70%29'%20points='405,136.798%20375.202,107%20256,226.202%20136.798,107%20107,136.798%20226.202,256%20107,375.202%20136.798,405%20256,285.798%20375.202,405%20405,375.202%20285.798,256'/></svg>") !important;
}

ion-app,
ion-nav,
ion-tab,
ion-tabs,
.app-root {
  -webkit-transition: bottom 0.3s;
  transition: bottom 0.3s;
}

.calendar-day {
  padding: 15px 0px !important;
}

:host .calendar-days-of-week {
  color: #262626;
}

#cancel-button {
  height: 47px !important;
}

#confirm-button {
  height: 47px !important;
}

.btn-app-secondary {
  background-color: #ffc107;
  border-color: #031e46;
  color: #212529;
}

.main-card {
  min-height: 80vh;
}

.grecaptcha-badge {
  visibility: collapse !important;
}

iframe {
  bottom: 94px !important;
  right: -3px !important;
}

// #webWidget {
//   top: 50px !important;
// }

.add-top-padding {
  top: 50px !important;
}

.text-danger {
  color: #bc170d !important;
}

.login-popup-modal{
  --height: auto;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  padding-right: 7px;
  padding-left: 7px;


  ::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }
  
  ion-toolbar {
    --background: #ffc107;
    --color: black;
    ion-title{
      padding-left: 10px;
      padding-right: unset;
      text-align: unset;
      font-size: 16px;
      font-weight: bold;
    }
  }

}
.maintenance-alert {
  align-items: start;
  padding-top: 30px;
}